import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from 'react'
import cs from 'classnames'
import { useSpring, animated } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import EventEmitter from 'eventemitter3'
import normalizeWheel from 'normalize-wheel'
import { throttle } from 'throttle-debounce'
import Helmet from 'react-helmet'
import Measure from 'react-measure'
import style from './HorizontalScrolling.module.scss'
import GlobalContext from '../GlobalContext'
import ScrollContext from './ScrollContext'

const eventEmitter = new EventEmitter()
const emitScrollThrottled = throttle(10, x => {
  eventEmitter.emit('scroll', x)
})

const smoothConfig = { mass: 1, tension: 60, friction: 20 }

const ToRightButton = () => {
  const { eventEmitter: ee, scrollTo } = useContext(ScrollContext)
  const [isScrolled, setIsScrolled] = useState(false)

  const updateIsScrolled = useCallback(
    x => {
      if (x > 100 && !isScrolled) {
        setIsScrolled(true)
      }

      if (x <= 100 && isScrolled) {
        setIsScrolled(false)
      }
    },
    [isScrolled]
  )

  useEffect(() => {
    ee.on('scroll', updateIsScrolled)

    return () => ee.removeListener('scroll', updateIsScrolled)
  }, [updateIsScrolled])

  return (
    <button
      type="button"
      className={cs({
        [style.toRight]: true,
        [style.toRightHidden]: isScrolled,
      })}
      onClick={() => scrollTo(window.innerWidth, smoothConfig)}
    />
  )
}

let currentBind = f => f
const HorizontalScrolling = ({
  addHeaderOffset = true,
  className = '',
  children,
}) => {
  const { isLargeScreen } = useContext(GlobalContext)
  const [containerBounds, setContainerBounds] = useState({
    width: 0,
    height: 0,
  })
  const [scrollProps, setScrollProps] = useSpring(() => ({
    x: 0,
    onFrame: ({ x }) => {
      emitScrollThrottled(x)
    },
  }))

  const scrollTo = (x, config = {}) => {
    let scrollBorderX = containerBounds.width - window.innerWidth
    scrollBorderX = scrollBorderX > 0 ? scrollBorderX : 0
    if (x <= 0) {
      x = 0
    } else if (x > scrollBorderX) {
      x = scrollBorderX
    }

    setScrollProps({
      x,
      config,
    })
  }

  const bind = useGesture(
    {
      onWheel: ({ event }) => {
        const { pixelX, pixelY } = normalizeWheel(event)
        const currentScrollX = scrollProps.x.getValue()
        const inc =
          Math.abs(pixelX) > Math.abs(pixelY) ? pixelX * 6 : pixelY * 6

        if (Math.abs(inc) > 1) {
          scrollTo(currentScrollX + inc)
        }
      },
    },
    {
      domTarget: typeof window !== 'undefined' ? window : null,
    }
  )

  useEffect(() => {
    scrollTo(scrollProps.x.getValue())
  }, [containerBounds])

  useEffect(() => {
    currentBind()
    if (isLargeScreen) {
      currentBind = bind()
    }
    return currentBind
  }, [bind, isLargeScreen])

  return (
    <ScrollContext.Provider value={{ scrollProps, scrollTo, eventEmitter }}>
      <Helmet htmlAttributes={{ class: 'horizontal-scrolling-enabled' }} />
      <div className={style.root}>
        <animated.div
          style={{
            transform: scrollProps.x.interpolate(
              x => `translate3d(${-x}px, 0, 0)`
            ),
          }}
        >
          <Measure
            bounds
            onResize={contentRect => {
              setContainerBounds(contentRect.bounds)
            }}
          >
            {({ measureRef }) => (
              <div
                ref={measureRef}
                className={cs(className, {
                  [style.container]: true,
                  'header-padding-offset': addHeaderOffset,
                })}
              >
                {children}
              </div>
            )}
          </Measure>
        </animated.div>
        <ToRightButton />
      </div>
    </ScrollContext.Provider>
  )
}

export default HorizontalScrolling
